import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";
import { Filter } from "components/Filter";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SelectingTable from "components/Table/SelectingTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
import { getCheck } from "api";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { TrakingModal } from "./TrakingModal";
import { ExportTrackingData } from "./ExportTrackingData";

export const Tracking = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const [checks, setChecks] = useState({});
  const [modalData, setModalData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFilterPage, setOpenFilterPage] = useState(true);
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({
    hasFilter: false,
    groupsConfiguration: {},
    groups: {},
  });
  const [rangeDates, setRangeDates] = useState(null);
  const [fromDate, setFromDate] = useState(dayjs.utc());
  const [getData, setGetData] = useState(false);
  const [enableDates, setEnableDates] = useState([]);

  const headCells = [
    {
      id: "thumbnail",
      numeric: false,
      disablePadding: true,
      label: "",
      isAvatar: true,
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: t("employee:fullName"),
    },
    {
      id: "department",
      numeric: false,
      disablePadding: true,
      label: t("employee:department"),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("shiftAttendance:Date"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: t("shiftAttendance:Type"),
    },
    {
      id: "schedule",
      numeric: false,
      disablePadding: true,
      label: t("shiftAttendance:WorkSchedule"),
    },
    {
      id: "timeClock",
      numeric: false,
      disablePadding: true,
      label: t("shiftAttendance:Reloj"),
    },
  ];

  const actionOnClick = async (row) => {
    const data = checks?.data.find((check) => check._id === row.id);
    setModalData(data);
    setOpen(true);
  };

  useEffect(() => {
    const lista = async () => {
      try {
        setGetData(true);

        const { data } = await getCheck(fromDate.format("YYYY-MM-DD"));
        setChecks(data);

        const rows = data?.data?.map((item) => ({
          id: item?._id,
          thumbnail: item?.employee?.thumbnail,
          employee: item?.employee?.fullName,
          department: item?.employee?.department,
          date: item?.registerAt,
          type: t(`shiftAttendance:${item?.type}`),
          schedule: item?.schedule?.name,
          timeClock: item?.timeClock?.name,
        }));

        let typeFilters = [...new Set(data.data.map((i) => i.type))];
        typeFilters = typeFilters.map((type) => t(`shiftAttendance:${type}`));
        let datesWithData = data.datesWithData;
        setEnableDates(datesWithData);

        let minDate = dayjs();
        let maxDate = dayjs();

        if (datesWithData.length > 1) {
          minDate = dayjs.utc(datesWithData[0]);
          maxDate = dayjs.utc(datesWithData[datesWithData.length - 1]);
        } else if (datesWithData.length === 0) {
          minDate = dayjs.utc(datesWithData[0]);
          maxDate = dayjs.utc(datesWithData[0]);
        }

        setData(rows);
        setFilters({
          hasFilter: false,
          groupsConfiguration: {
            department: {
              title: t("organization:Department"),
              type: "checkbox",
              options: data.filterOptions.departments,
            },
            type: {
              title: t("employee:Type"),
              type: "checkbox",
              options: typeFilters,
            },
            employee: {
              title: t("employee:Employee"),
              type: "select",
              options: data.filterOptions.employees,
            },
          },
          groups: {
            department: new Set(),
            employee: new Set(),
            type: new Set(),
          },
        });
        setRangeDates({
          minDate: minDate,
          maxDate: maxDate,
        });
        setGetData(false);
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, [fromDate]);

  function disableDates(date) {
    return !enableDates.includes(date.format("YYYY-MM-DD"));
  }

  return (
    <>
      <Box
        sx={
          openFilterPage
            ? {
                display: "grid",
                gridTemplateColumns: "4fr 1fr",
                gap: "3rem",
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "calc(100vw - 48px)",
                },
              }
            : {}
        }
      >
        {!getData ? (
          <SelectingTable
            headCells={headCells}
            rows={renderData}
            selected={selected}
            setSelected={setSelected}
            name={t("shiftAttendance:Tracking")}
            actionOnClick={actionOnClick}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item={true}>
                {rangeDates ? (
                  <DatePicker
                    label={t("shiftAttendance:fromDate")}
                    value={fromDate}
                    minDate={rangeDates.minDate}
                    maxDate={rangeDates.maxDate}
                    onChange={(value) => {
                      setData([]);
                      setFromDate(value);
                    }}
                    shouldDisableDate={disableDates}
                    format="YYYY/MM/DD"
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item={true} xs={12} md={3}>
                <Stack direction="row">
                  <Tooltip title="Filter">
                    <IconButton
                      size="large"
                      onClick={() => setOpenFilterPage(!openFilterPage)}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                  {renderData.length ? (
                    <ExportTrackingData
                      filterData={renderData}
                      allData={checks}
                    />
                  ) : (
                    ""
                  )}
                </Stack>
              </Grid>
            </Grid>
          </SelectingTable>
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: openFilterPage ? "100%" : "80vh",
            }}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              color="secondary"
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={"100%"}
            />
          </Box>
        )}
        {openFilterPage && (
          <Paper>
            <Filter
              title={t("shiftAttendance:trackingFilters")}
              filters={filters}
              setFilters={setFilters}
              renderData={data}
              setRenderData={setRenderData}
            />
          </Paper>
        )}
      </Box>
      <TrakingModal open={open} setOpen={setOpen} registro={modalData} />
    </>
  );
};
