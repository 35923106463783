import { BasicTable, Toast, UIButton } from "../../../components";
import { MenuNav } from "../MenuNav";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  TableCell,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  deleteEmailTemplate,
  getEmails,
  getEmailTemplate,
  getTemplateTags,
  patchEmailTemplate,
  postEmailTemplate,
} from "api";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { FormTemplate } from "./FormTemplate";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import {
  Add as AddIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useSelector } from "react-redux";
import { useAccess } from "hooks";

export const Templates = () => {
  const { t } = useTranslation(["settings"]["general"]);

  const { tenant } = useSelector(tenantSettingsSelect);

  const initialValues = {
    title: "",
    subject: "",
    isActive: false,
    html: "<p><br></p>",
    email: "",
  };

  const [emails, setEmails] = useState([]);
  const [templatesEmail, setTemplatesEmail] = useState([]);
  const [rows, setRows] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [updateData, setUpdateData] = useState(null);
  const [edit, setEdit] = useState(false);

  const { HasPermissions } = useAccess();
  const { canAdd, canEdit, canDelete } = HasPermissions(
    "/cereza-organization/settings/templates"
  );

  const rowClick = (row) => {
    let template = templatesEmail.find((e) => e._id === row.id);

    customTags.forEach(({ variable, idVariable }) => {
      template.html = template.html.split(idVariable).join(variable);
    });
    setValues(template);

    setEdit(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getTemplateTags();

        setCustomTags(
          data.map((d) => {
            return {
              ...d,
              variable: `$$${d.title}$$`,
              idVariable: "$$" + d._id + "$$",
            };
          })
        );
      } catch (error) {
        console.log("Error get data ", error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (customTags.length) {
        try {
          const { data } = await getEmails();

          setEmails(
            data.map((e) => {
              return {
                ...e,
                value: e._id,
                label:
                  t(`settings:${e.module}`) + " - " + t(`settings:${e.title}`),
                context: e.context.map((c) => {
                  return {
                    variable: `{{${c}}}`,
                    label: t(`settings:${c}`),
                  };
                }),
              };
            })
          );
        } catch (error) {
          console.log("Error get data: ", error);
        }
      }
    };

    getData();
  }, [customTags]);

  useEffect(() => {
    const getData = async () => {
      if (emails.length) {
        try {
          const { data } = await getEmailTemplate();
          setRows(
            data.map(({ _id, title, subject, email, isActive }) => {
              let emailData = emails.find((e) => e._id === email);

              return {
                id: _id,
                title,
                subject,
                email: t(`settings:${emailData.title}`),
                module: t(`settings:${emailData.module}`),
                isActive: isActive === true ? t("general:Si") : t("general:No"),
              };
            })
          );

          setTemplatesEmail(data);
        } catch (error) {
          console.log("Erro get data: ", error);
        }
      }
    };

    getData();
  }, [emails, updateData]);

  const reCustomTags = /\$\$(.*?)\$\$/g;
  const reVariables = /\{\{(.*?)\}\}/g;

  const onSubmit = async (formValues, { resetForm }) => {
    let save = true;
    let message = "";

    let updateValues = JSON.parse(JSON.stringify(formValues));
    const emailVariables = emails
      .find((e) => e.value === updateValues.email)
      .context.map((o) => o.variable);
    const emailCustomTags = customTags.map((c) => c.variable);
    const differenceVariables = difference(
      updateValues.html.match(reVariables),
      emailVariables
    );
    const differenceCustomTags = difference(
      updateValues.html.match(reCustomTags),
      emailCustomTags
    );

    if (differenceVariables.length) {
      save = false;
      message += t("settings:ErrorVariables") + differenceVariables.join(", ");
    }

    if (differenceCustomTags.length) {
      save = false;
      message +=
        t("settings:ErrorCustomTags") + differenceCustomTags.join(", ");
    }

    if (save) {
      let message = t("settings:EmailTemplateSuccessfullyUpdated");

      if (!/<html>/.test(updateValues.html)) {
        updateValues.html = `<html><head></head><body>${formValues.html}</body></html>`;
      }

      try {
        customTags.forEach(({ variable, idVariable }) => {
          updateValues.html = updateValues.html
            .split(variable)
            .join(idVariable);
        });

        if (updateValues?._id) {
          await patchEmailTemplate(updateValues);
        } else {
          await postEmailTemplate(updateValues, tenant._id);
          message = t("settings:EmailTemplateSuccessfullyCreated");
          resetForm();
        }

        setValues(initialValues);
        setUpdateData(Math.random());
        setEdit(false);
        Toast.fire({
          icon: "success",
          title: message,
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: t("settings:ErrorSendingData"),
        });
        console.log(error);
      }
    } else {
      if (message) {
        Toast.fire({
          icon: "error",
          title: message,
        });
      }
    }
  };

  const onDelete = async () => {
    try {
      await deleteEmailTemplate(values._id);
      setUpdateData(Math.random());
      setValues(initialValues);
      setEdit(false);
    } catch (error) {
      Toast.fire({ icon: "error", title: `${error.reponse.data.message}` });
    }
  };

  return (
    <Paper>
      <MenuNav ubication={"Templates"} />
      <Box m={2}>
        <Toolbar disableGutters>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {t("settings:EmailTemplates")}
          </Typography>
          {canAdd && !edit && (
            <IconButton
              ml={2}
              color="primary"
              onClick={() => {
                setValues(initialValues);
                setEdit(true);
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Toolbar>
        {!edit && (
          <BasicTable rows={rows} handleClick={rowClick}>
            <TableCell>{t("settings:Title")}</TableCell>
            <TableCell>{t("settings:Subject")}</TableCell>
            <TableCell>{t("settings:Email")}</TableCell>
            <TableCell>{t("settings:Module")}</TableCell>
            <TableCell>{t("settings:Active")}</TableCell>
          </BasicTable>
        )}
      </Box>
      <Box p={2}>
        {emails.length > 0 && edit && (
          <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormTemplate
                  formik={formik}
                  emails={emails}
                  customTags={customTags}
                  canEdit={canEdit}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <UIButton
                    variant="contained"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => setEdit(false)}
                    disabled={formik.isSubmitting}
                    sx={{}}
                  />
                  {((canAdd && !formik?.values?._id) ||
                    (canEdit && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {!isEqual(formik.values, initialValues) && (
                    <>
                      {canDelete && values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                onDelete();
                                Swal.fire({
                                  title: t("general:Deleted"),
                                  text: t("general:YourFileHasBeenDeleted"),
                                  icon: "success",
                                });
                              }
                            });
                          }}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {canAdd && (
                        <UIButton
                          label={t("general:Nuevo")}
                          onClick={() => {
                            setValues(initialValues);
                            formik.setValues(initialValues);
                          }}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Paper>
  );
};
