import { Form, Formik } from "formik";
import { FormAuthorizationSettings } from "./FormAuthorizationSettings";
import { UIButton } from "components";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle, Box, Stack } from "@mui/material";
import { useEffect } from "react";

const areArraysIdentical = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((element, index) => element === arr2[index]);
};

const areObjectsEqual = (obj1, obj2) => {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    if (typeof obj1[key] === "object" && obj1[key] !== null) {
      return areObjectsEqual(obj1[key], obj2[key]);
    }
    return obj1[key] === obj2[key];
  });
};

const areValuesDifferent = (initialValues, latestChanges) => {
  const parsedInitialValues =
    typeof initialValues === "string"
      ? JSON.parse(initialValues)
      : initialValues;
  const parsedLatestChanges =
    typeof latestChanges === "string"
      ? JSON.parse(latestChanges)
      : latestChanges;

  if (parsedInitialValues && parsedLatestChanges) {
    if (
      !areArraysIdentical(
        parsedInitialValues?.approvers,
        parsedLatestChanges?.approvers
      )
    ) {
      return true;
    }

    if (
      !areArraysIdentical(
        parsedInitialValues?.authorizers,
        parsedLatestChanges?.authorizers
      )
    ) {
      return true;
    }

    const {
      approvers: _,
      authorizers: __,
      ...restInitialValues
    } = parsedInitialValues;
    const {
      approvers: ___,
      authorizers: ____,
      ...restLatestChanges
    } = parsedLatestChanges;

    return !areObjectsEqual(restInitialValues, restLatestChanges);
  } else {
    return false;
  }
};

export const FormikFlows = ({
  auth,
  flow,
  onSubmit,
  employees,
  isDirty,
  setIsDirty,
  submitButtonRef,
}) => {
  const { t } = useTranslation(["organization", "general"]);

  return (
    <>
      {auth && employees && (
        <Formik
          initialValues={auth}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(formik) => {
            useEffect(() => {
              const savedFormValues = sessionStorage.getItem(
                `authorizationForm-${flow}`
              );

              if (savedFormValues) {
                const parsedFormValues = JSON.parse(savedFormValues);
                if (parsedFormValues) {
                  formik.setValues(parsedFormValues.LatestChanges);
                } else {
                  formik.setValues(auth);
                }
              }
            }, [formik.setValues]);

            useEffect(() => {
              const savedFormValues = sessionStorage.getItem(
                `authorizationForm-${flow}`
              );
              const parsedFormValues = savedFormValues
                ? JSON.parse(savedFormValues)
                : null;

              if (!parsedFormValues) {
                sessionStorage.setItem(
                  `authorizationForm-${flow}`,
                  JSON.stringify({
                    initialValues: formik.values,
                    LatestChanges: formik.values,
                  })
                );
              } else {
                sessionStorage.setItem(
                  `authorizationForm-${flow}`,
                  JSON.stringify({
                    ...parsedFormValues,
                    LatestChanges: formik.values,
                  })
                );
              }

              const isDifferent = areValuesDifferent(
                parsedFormValues?.initialValues,
                formik.values
              );
              setIsDirty(isDifferent);
            }, [formik.values]);

            useEffect(() => {
              const handleUnload = () => {
                Object.keys(sessionStorage).forEach((key) => {
                  if (key.startsWith("authorizationForm-")) {
                    sessionStorage.removeItem(key);
                  }
                });
              };

              window.addEventListener("beforeunload", handleUnload);

              window.addEventListener("popstate", handleUnload);
              const originalPushState = history.pushState;
              history.pushState = function (...args) {
                handleUnload();
                return originalPushState.apply(this, args);
              };

              return () => {
                window.removeEventListener("beforeunload", handleUnload);
                window.removeEventListener("popstate", handleUnload);
              };
            }, []);

            return (
              <Form id={"d"} autoComplete="off">
                <Box p={2} pt={0}>
                  <FormAuthorizationSettings
                    formik={formik}
                    employees={employees}
                  />
                  {isDirty && (
                    <Alert severity="warning">
                      <AlertTitle>{t("organization:Warning")}</AlertTitle>
                      {t("organization:WarningText")}
                    </Alert>
                  )}
                  <br />
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      ref={submitButtonRef}
                      sx={{}}
                    />
                    {isDirty && (
                      <UIButton
                        label={t("organization:descartarCambios")}
                        loading={formik.isSubmitting}
                        onClick={() => window.location.reload()}
                        sx={{}}
                      />
                    )}
                  </Stack>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
