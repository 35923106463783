import { Divider, Grid } from "@mui/material";
import {
  UISelectCheckmarks,
  UITextField,
  UISwitch,
  UISelect,
} from "components";
import { useTranslation } from "react-i18next";
import { translateSelectors } from "helpers";
import { useEffect } from "react";

const NOTIFICATIONS_METHODS = [
  { value: "BROADCAST", label: "BROADCAST" },
  { value: "GROUP", label: "GROUP" },
  { value: "CHAIN", label: "CHAIN" },
];

const AUTHORIZATION_METHOD_CHOICES = [
  { value: "ONE", label: "ONE" },
  { value: "ALL", label: "ALL" },
  { value: "MAJORITY", label: "MAJORITY" },
];

const AUTOMATIC_METHOD_CHOICES = [
  { value: "APPROVE", label: "APPROVE" },
  { value: "REJECT", label: "REJECT" },
];

export const FormAuthorizationSettings = ({ formik, employees }) => {
  const { t } = useTranslation(["organization", "options"]);

  useEffect(() => {
    if (formik.values.hierarchicalOrder && formik.values.approvers.length > 0) {
      formik.setFieldValue("approvers", []);
    }
  }, [
    formik.values.hierarchicalOrder,
    formik.values.approvers,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    if (
      !formik.values.hierarchicalOrder &&
      formik.values.maxHierarchyDepth !== 0
    ) {
      formik.setFieldValue("maxHierarchyDepth", 0);
    } else if (
      formik.values.hierarchicalOrder &&
      formik.values.maxHierarchyDepth === 0
    ) {
      formik.setFieldValue("maxHierarchyDepth", 1);
    }
  }, [
    formik.values.hierarchicalOrder,
    formik.values.maxHierarchyDepth,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    if (
      !formik.values.automaticAuthorized &&
      formik.values.automaticMethod !== undefined
    ) {
      formik.setFieldValue("automaticMethod", null);
    }
  }, [
    formik.values.automaticAuthorized,
    formik.values.automaticMethod,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    if (formik.values.automaticAuthorized && formik.values.waitingTime === 0) {
      formik.setFieldValue("waitingTime", 1);
    } else if (
      !formik.values.automaticAuthorized &&
      formik.values.waitingTime !== 0
    ) {
      formik.setFieldValue("waitingTime", 0);
    }
  }, [
    formik.values.automaticAuthorized,
    formik.values.waitingTime,
    formik.setFieldValue,
  ]);

  return (
    <>
      <Divider>{t("organization:Nivel1")}</Divider>
      <br />

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UISelect
            label={t("organization:authorizationMethod")}
            name="authorizationMethod"
            formik={formik}
            options={translateSelectors(t, AUTHORIZATION_METHOD_CHOICES)}
            isrequired={true}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UISwitch
            label={t("organization:hierarchicalOrder")}
            name={"hierarchicalOrder"}
            formik={formik}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <UISelectCheckmarks
            label={t("organization:approvers")}
            name={"approvers"}
            formik={formik}
            options={employees}
            fieldRender={"label"}
            isrequired={true}
            disabled={formik.values.hierarchicalOrder}
          />
        </Grid>
      </Grid>

      <br />
      <Divider>{t("organization:Nivel2")}</Divider>
      <br />

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UITextField
            label={t("organization:maxHierarchyDepth")}
            name="maxHierarchyDepth"
            formik={formik}
            isrequired={true}
            type="number"
            disabled={!formik.values.hierarchicalOrder}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UISwitch
            label={t("organization:finalAuthorizer")}
            name={"finalAuthorizer"}
            formik={formik}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <UISelectCheckmarks
            label={t("organization:authorizers")}
            name={"authorizers"}
            formik={formik}
            options={employees}
            fieldRender={"label"}
            isrequired={true}
            disabled={!formik.values.finalAuthorizer}
          />
        </Grid>
      </Grid>

      <br />
      <Divider>{t("organization:configuracionesAdicionales")}</Divider>
      <br />

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <UISwitch
            label={t("organization:automaticAuthorized")}
            name={"automaticAuthorized"}
            formik={formik}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UISelect
            label={t("organization:automaticMethod")}
            name="automaticMethod"
            formik={formik}
            options={translateSelectors(t, AUTOMATIC_METHOD_CHOICES)}
            isrequired={true}
            disabled={!formik.values.automaticAuthorized}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UITextField
            label={t("organization:waitingTime")}
            name="waitingTime"
            formik={formik}
            isrequired={true}
            type="number"
            disabled={!formik.values.automaticAuthorized}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={4}>
          <UISelect
            label={t("organization:sendNotificationMethod")}
            name="deliveryMethod"
            formik={formik}
            options={translateSelectors(t, NOTIFICATIONS_METHODS)}
            isrequired={true}
          />
        </Grid>
      </Grid>
      <br />
    </>
  );
};
